const state = {
  tradeActive:'USDT',
  quoteAsset:'USDT',
  baseAsset:'BTC',
  // trade:'BTC/USDT',
  trade:'',
  symbol:'btcusdt',
  tickSize:2,
  stepSize:2,
  resolution:15,
  TokenCode:'',
  ip:'',
  country:'',
  local:'zh-cn',
  theme:localStorage.getItem('skin')||'dark', // 主题色
  upDown:0, // 0 = 红涨绿跌; 1 = 绿涨红跌
  score:4.0, //评分
  symbolList:[],  // 所有的交易对
  tickerDayList:[], // 24h价格变动情况
  depthData:[], // 深度图数据
  asks:[], // 买单深度
  bids:[], // 买单深度
  asksDep:[], // 买单深度
  bidsDep:[], // 买单深度
  wsIsOp:false,
  fix:0,
  isLine:true, //
  isShow:false,
  tradeList:[], // 历史成交
  wsTickerList:[], // 
  quoteAssetList:[], 
  baseAssetList:[],
  zorroJson:[],
}
export default state;