// import * as types from './mutation-types'
import { SET_EXCHANGEINFO,SET_ZORRO,SET_TRADEACTIVE,SET_QUOTEASSET,SET_BASEASSET,SET_SCORE,SET_TRADE,SET_TICKERDAY,SET_WSTICKER,SET_RESOLUTION,SET_ASKS,SET_BIDS,SET_ASKSDEP,SET_BIDSDEP,SET_FIX,SET_TRADELIST,SET_DEPTHDATA,SET_ISLINE,SET_LOCAL,SET_THEME,SET_UPDOWN,SET_WSISOP,SET_TOKENCODE,SET_IP,SET_COUNTRY,SET_QP,SET_BASEASSETLIST,SET_ISSHOW} from './mutation-type'
const actions = {
  setExcnInfo({commit}, data) {
    commit(SET_EXCHANGEINFO,data)
  },
  setTokenCode({commit},data){
    commit(SET_TOKENCODE,data)
  },
  setTradeActive({commit},data){
    commit(SET_TRADEACTIVE,data)
  },
  setZorro({commit},data){
    commit(SET_ZORRO,data)
  },
  setQuoteasset({commit},data){
    commit(SET_QUOTEASSET,data)
  },
  setBaseAssetList({commit},data){
    commit(SET_BASEASSETLIST,data)
  },
  setBaseAsset({commit},data){
    commit(SET_BASEASSET,data)
  },
  setScore({commit},data){
    commit(SET_SCORE,data)
  },
  setTrade({commit},data){
    commit(SET_TRADE,data)
  },
  setTickerDay({commit},data){
    commit(SET_TICKERDAY,data)
  },
  setWsTicker({commit},data){
    commit(SET_WSTICKER,data)
  },
  setResolution({commit},data){
    commit(SET_RESOLUTION,data)
  },
  setAsks({commit},data){
    commit(SET_ASKS,data)
  },
  setBids({commit},data){
    commit(SET_BIDS,data)
  },
  setAsksDep({commit},data){
    commit(SET_ASKSDEP,data)
  },
  setBidsDep({commit},data){
    commit(SET_BIDSDEP,data)
  },
  setFix({commit},data){
    commit(SET_FIX,data)
  },
  setTradeList({commit},data){
    commit(SET_TRADELIST,data)
  },
  setIsShow({commit},data){
    commit(SET_ISSHOW,data)
  },
  setIsLine({commit},data){
    commit(SET_ISLINE,data)
  },
  setDepthData({commit},data){
    commit(SET_DEPTHDATA,data)
  },
  setLocal({commit},data){
    commit(SET_LOCAL,data)
  },
  setTheme({commit},data){
    commit(SET_THEME,data)
  },
  setUpDown({commit},data){
    commit(SET_UPDOWN,data)
  },
  setCountry({commit},data){
    commit(SET_COUNTRY,data)
  },
  setIp({commit},data){
    commit(SET_IP,data)
  },
  setQp({commit},data){
    commit(SET_QP,data)
  },
  setWsIsOp({commit},data){
    commit(SET_WSISOP,data)

  }
}

export default actions