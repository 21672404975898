// export const review = state => state.review
import {numToStr} from '@/utils/baseFn/filter'
const getters={
  getSymbolList: state =>{
  },
  getTokenCode:state=>{
      let tokenCode= state.symbolList.filter((item)=>{
        return item.symbol===state.symbol.toLocaleUpperCase()
      })
      return tokenCode[0].code
  },
  getBaseAssetByTokenCode:state=>{
    let baseAsset= state.symbolList.filter(item=>{
      return item.code==state.TokenCode
    })
    return baseAsset
  },
  getScore:state=>{
    return state.score
  },
  getSymbolTicker:state=>{
    // let arr=[]
    // let list =state.baseAssetList
    let trade=state.tradeActive||'USDT'
    let arr=state.tickerDayList.filter(item=>{
      return item.quoteAsset==trade
    })
    
    // let arr1=state.tickerDayList||[]
    //   if(list&&list.length>0){
    //     list.forEach(item=>{
    //       if(arr1&&arr1.length>0){
    //         arr1.forEach(val=>item.symbol===val.symbol&&arr.push({...item,...val}));
    //       }
    //     });
    //   }

      if(arr&&arr.length>0){
        arr.forEach(item=>{
          if(state.wsTickerList&&state.wsTickerList.length>0){
            state.wsTickerList.forEach(val=>{
              if(item.symbol===val.s){
                
                item.time=val.E
                item.askPrice=val.c
                item.askPrice2=val.c
                item.highPrice=val.h
                item.openPrice=val.o
                item.lowPrice=val.l
                item.volume=val.v
                item.quoteVolume=val.q
              }
            });
          }
        });
      }
      // console.log(arr);
    return arr
  },
  getAsks:state=>{
    return state.asks
  },
  getTradeList:state=>{
    return state.tradeList
  },
  getShow:state=>{
    return state.isShow
  },
}
export default getters