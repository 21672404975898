import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import {router} from './router'
import store from './store'
import {i18n} from './utils/i18n'
Vue.use(Vuex)

import { Select,Switch,Tabs,Input,Icon,Layout,Menu,Spin,Dropdown,Message,Tooltip,Rate } from 'ant-design-vue';
import 'ant-design-vue/lib/date-picker/style/css'; // 加载 CSS
Vue.use(Rate)
Vue.use(Select)
Vue.use(Switch)
Vue.use(Tabs)
Vue.use(Input)
Vue.use(Icon)
Vue.use(Layout)
Vue.use(Menu)
Vue.use(Spin)
Vue.use(Dropdown)
Vue.use(Message)
Vue.use(Tooltip)
// Vue.use(Empty)
// console.log(process.env.NODE_ENV)
import preventClick from './utils/controlClickState'
Vue.use(preventClick)
import util from './utils/utils'

Vue.use(util)

// import '@/assets/css/global.less'

import './style/reset.css'
import './utils/filters'
import axios from 'axios'

Vue.config.productionTip = false
Vue.prototype.$axios=axios

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
