export const SET_EXCHANGEINFO = 'SET_EXCHANGEINFO' 
export const SET_TRADEACTIVE = 'SET_TRADEACTIVE'
export const SET_QUOTEASSET = 'SET_QUOTEASSET'
export const SET_ZORRO = 'SET_ZORRO'  
export const SET_BASEASSET = 'SET_BASEASSET'  
export const SET_SCORE = 'SET_SCORE'  
export const SET_TRADE = 'SET_TRADE'  
export const SET_TICKERDAY = 'SET_TICKERDAY'  
export const SET_WSTICKER = 'SET_WSTICKER'  
export const SET_RESOLUTION = 'SET_RESOLUTION'  
export const SET_ASKS = 'SET_ASKS'  
export const SET_BIDS = 'SET_BIDS'  
export const SET_ASKSDEP = 'SET_ASKSDEP'  
export const SET_BIDSDEP = 'SET_BIDSDEP'  
export const SET_FIX = 'SET_FIX'  
export const SET_TRADELIST = 'SET_TRADELIST'  
export const SET_DEPTHDATA = 'SET_DEPTHDATA'  
export const SET_ISLINE = 'SET_ISLINE'  
export const SET_LOCAL = 'SET_LOCAL'  
export const SET_THEME = 'SET_THEME'  
export const SET_UPDOWN = 'SET_UPDOWN'  
export const SET_WSISOP = 'SET_WSISOP'  
export const SET_TOKENCODE = 'SET_TOKENCODE'  
export const SET_IP = 'SET_IP'  
export const SET_COUNTRY = 'SET_COUNTRY' //是否国内  
export const SET_QP = 'SET_QP'   // 精度
export const SET_BASEASSETLIST = 'SET_BASEASSETLIST' 
export const SET_ISSHOW = 'SET_ISSHOW' 
