import Vue from 'vue'
import VueRouter from 'vue-router'
const Home = () => import('@/views/Home.vue')
// const Market = () => import('@/views/market.vue')

Vue.use(VueRouter)

const routes = [
  {path:'/',component: Home},
  {path:'/:lang/',redirect: '/:lang/market/'},
  // {path:'/:lang/',name:"index",component: () => import('@/views/market.vue')},
  {path: '/:lang/market/:token2?/:token1?',name:"index",component: () => import('@/views/market.vue')},
]
const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  // base: process.env.BASE_URL,
  routes,
})
// 解决NavigationDuplicated
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace



VueRouter.prototype.replace = function (location) {
  return originalReplace.call(this, location).catch(err => err)
}
router.beforeEach((to, from, next) => {
  // ...
  let local= to.fullPath.split('/')[1]
  if(local!=='zh'&&local!=='zh-cn'&&local!=='en'&&local!=='ja'&&local!=='vi'&&local!=='id'){
    if(to.fullPath.includes('.html')){
      next(`/en/market/${to.fullPath.split('/')[3]}/${to.fullPath.split('/')[4]}`)
    }else{
      next('en/market/')
    }
  }else{

    next()
  }
    // console.log(to.fullPath);
    // document.title = 'wikibit'
})

export {router,routes}
