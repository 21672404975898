<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
export default {
  provide(){
    return {
      reload:this.reload
    }
  },  
  data(){
    return {
      isRouterAlive:true
    }
  },  
  mounted() {
        // this.$store.dispatch('setIp',cip)
        if (sessionStorage.getItem("store") ) {
            this.$store.replaceState(Object.assign({},
            this.$store.state,JSON.parse(sessionStorage.getItem("store"))))
          }
          //在页面刷新时将vuex里的信息保存到sessionStorage里
          window.addEventListener("beforeunload",()=>{
          sessionStorage.setItem("store",JSON.stringify(this.$store.state))
          })
  },
  created() {
    
  },
  methods:{
    reload(){
      this.isRouterAlive=false
      this.$nextTick(function(){
        this.isRouterAlive=true
      })
    },
  }
}
</script>
<style>
 html,
  body,
  #app{
    height: 100%;
    background-size: cover;
    text-align: center;
  }

</style>
