// src/utils下创建i18n/index.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store/index'

// 引入各个语言配置文件
import zh from '../locales/TXT_zh-CN.json'
import en from '../locales/TXT_en.json'
import ko from '../locales/TXT_ko.json'
import ja from '../locales/TXT_ja.json'
import vi from '../locales/TXT_vi.json'
// import tw from '../locales/TXT_zh-TW.json'
import hk from '../locales/TXT_zh.json'
import id from '../locales/TXT_id.json'

Vue.use(VueI18n)
//this.$i18n.locale // 通过切换 locale 的值来实现语言切换
// 创建vue-i18n实例i18n
const i18n = new VueI18n({
  // 设置默认语言
  locale: store.state.local||'zh-cn', // 语言标识
  // 添加多语言（每一个语言标示对应一个语言文件）
  messages: {
    'zh-cn':zh,
    'en':en,
    'ko':ko,
    'ja':ja,
    'vi':vi, 
    // 'zh-tw':tw,
    'zh':hk,
    'id':id,
  }
})
// 非 vue 文件中使用这个方法
const translate = (localeKey) => {
  // const locale = store.state.local || "zh-cn"
  // const hasKey = i18n.te(localeKey, locale)  // 使用i18n的 te 方法来检查是否能够匹配到对应键值
  const translatedStr = i18n.t(localeKey) 
  // if (hasKey) {
      return translatedStr
  // }
  // return localeKey
}

// 暴露i18n
export {i18n , translate}
// export default i18n