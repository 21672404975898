// 过滤器函数
import * as math from 'mathjs'
import { Decimal } from 'decimal.js'
import moment from 'moment'

/**
 * 格式化日期
 * @param {*} val
 */
export function fmtDate (val) {
  if (!val) return ''
  return moment(val).format('HH:mm:ss')
}
/**
 * 获取截止日期
 * @param {*} val 
 */
export function dateAddYear(date, years) {
  var now = new Date(date);
  var intYear = now.getFullYear() + parseInt(years);
  var intMonth = now.getMonth() + 1; //正常的月份，
  var intDay = now.getDate() - 1; //日期-1
  if (intDay == 0) {
  intMonth--; //减少一个月
    if (intMonth == 0) {
    intYear--; //0:减少一年
    intMonth = 12;
    intDay = 31;
    }
    else if (intMonth == 4 || intMonth == 6 || intMonth == 9 || intMonth == 11) {
    intDay = 30; //4,6,9,11:30天
    }
    else if (intMonth == 2) {
    intDay = 28; //2:28/29
    if (intYear % 4 == 0) {
    intDay = 29;
    }
    } else {
    intDay = 31; //1,3,5,7,8,10,12 :31天
    }
  }
var strMonth = (intMonth) < 10 ? "0" + (intMonth).toString() : (intMonth).toString();
var strDay = (intDay) < 10 ? "0" + (intDay).toString() : (intDay).toString();
var strEndDate = intYear + "-" + strMonth + "-" + strDay;
return strEndDate;
}

/**
 * 路由
 * @param {*} val
 */
export function formateArrData (initialArr, name) {
  let nameArr = []
  for (let i in initialArr) {
    if (nameArr.indexOf(initialArr[i][`${name}`]) === -1) {
      nameArr.push(
        {
          meta:{title:initialArr[i].meta.parent},
          id:initialArr[i][`${name}`],
          children:[]
        }
      )
    }
  }
  nameArr.shift()
  function compare(arr){
    var result = [],isfl;
    for (var i = 0; i < nameArr.length; i++) {
      isfl= false;
     for (var j = 0; j < result.length; j++) {
          if (arr[i].id ===result[j].id) {   
              isfl = true;
             break;
          }
     }
     if (!isfl) {
         result.push(arr[i]);
     }
}
     return result;
  }
  let menu=compare(nameArr)
  
  initialArr.map((item,i)=>{
    menu.map(item2=>{
      if(item.parentId==item2.id){
        item2.children.push(item)
      }
    })
  })
  let menuList = menu
  return menuList
}
/**
 * 获取树形数据
 */
export function  toTree(data) {
  let result = []
  if(!Array.isArray(data)) {
      return result
  }
  data.forEach(item => {
      delete item.children;
  });
  
  let map = {};

  data.forEach(item => {
      map[item.id] = item;
  });
  data.forEach(item => {
      let parent = map[item.parentId];
      if(parent) {
          (parent.children || (parent.children = [])).push(item);
      } else {
          result.push(item);
      }
  });
  return result;
}

/**
 * 根据数组里某个Key的值去重
 */
 export function deweight (arr,key) {
  let ret = []

  arr.forEach((item, index, self) => {

      let compare = []

      ret.forEach((retitem, retindex, retself) => {

      compare.push(retitem[key])

      })

      if (compare.indexOf(item[key]) === -1) {
      
      ret.push(item)
      
      }

    })
    return ret
}
/**
 * 合并两个数组
 */
export function mergeArray(arr1, arr2) {
  const newArray = [];
  let length1 = arr1.length;
  let length2 = arr2.length;
  for (let i = 0; i < length1; i++) {
      for (let j = 0; j < length2; j++) {
          if (i === j) {
              let mergeItem = Object.assign(arr1[i], arr2[j]);
              newArray.push(mergeItem);
              break;
          }
      }
  }
  return newArray;
}

  export function getHost(){
      // 获取当前页面地址，如http://localhost:8080/admin/index
  let wPath = window.document.location.href;
  // 获取当前页面主机地址之后的目录，如：/admin/index
  let pathName =   location.pathname
  let pos = wPath.indexOf(pathName);
  // 获取主机地址，如：http://localhost:8080
  let localhostPath = wPath.substring(0, pos);
    return localhostPath
  }

/**
 * 英文首字母排序
 */
 export function creatCompare3(propertyName,sort) {
  return function (obj1,obj2) {
      var value1=obj1[propertyName];
      var value2=obj2[propertyName];
      if(sort===2||undefined){
        if(value1<value2){
            return -1
        }else if(value1>value2){
            return 1
        }else {
            return 0
        }
      }else if(sort===1){
        if(value1>value2){
            return -1
        }else if(value1<value2){
            return 1
        }else {
            return 0
        }

      }
  }
}

/**
 * 排序
 */
 export function creatCompare(propertyName,sort) {
  return function (obj1,obj2) {
      var value1=obj1[propertyName];
      var value2=obj2[propertyName];
      if(sort===2||undefined){
        return value1-value2
      }else if(sort===1){
        return value2-value1
      }
  }
}
/**
 * 涨幅排序
 */
 export function creatCompare2(propertyName,propertyName2,sort) {
  return function (obj1,obj2) {
      var value1=(obj1[propertyName]-obj1[propertyName2])/obj1[propertyName2]*100;
      var value2=(obj2[propertyName]-obj2[propertyName2])/obj2[propertyName2]*100;
      if(sort===2||undefined){
        return value1-value2
      }else if(sort===1){
        return value2-value1
      }
  }
}

/**
 * 状态
 */
export function fmtStatus (val, status) {
  const s = !!status
  return s ? status[val] : val
}
/**
 * 号码加密
 */
export function hideNum (value) {
  if(value){
    var len = value.length;
        var x = value.substring(3, len - 4);
        var values = value.replace(x, "****");
        return values;
  }

}
/**
 * 邮箱号码隐藏
 * @param {*} email
 */
export function hideEmail (email) {
  if (String(email).indexOf('@') > 0) {
    let newEmail; let str = email.split('@')
    let _s = ''
    if (str[0].length > 3) {
      _s = str[0].substr(0, 3)
      for (let i = 0; i < str[0].length - 3; i++) {
        _s += '*'
      }
    } else {
      _s = str[0].substr(0, 1)
      for (let i = 0; i < str[0].length - 1; i++) {
        _s += '*'
      }
    }
    newEmail = _s + '@' + str[1]
    return newEmail
  } else {
    return email
  }
}
/**
 * 格式化金额
 */
export function moneyFormats (num,scale) {
  if(isNaN(num)){
    return '0.00';
}
    let a=Number(num);  //转为数字格式
		// CNY USD EUR 欧元 
		let b=a.toLocaleString('en', { style: 'currency', currency: 'USD' });
		
		return b
}
/**
 * 格式化数字
 */
export function toLocaleString (num,scale) {
  if(isNaN(num)){
    return '0.00';
}
    let a=Number(num);  //转为数字格式
		// CNY USD EUR 欧元  minimumFractionDigits   useGrouping
		let b=a.toLocaleString('zh',{minimumFractionDigits :scale});
		
		return b
}
/**
 * 根据精度向下取整
 */
export function floorNum (num,scale) {
  let a=Math.floor(num * Math.pow(10, scale)) / Math.pow(10, scale)
  let b=''
  if(a>0){
    b='+'+a
  }else{
    b=a
  }
  return b
}
/**
 * 根据精度向上取整
 */
export function ceilNum (num, scale) {
  return Math.ceil(num * Math.pow(10, scale)) / Math.pow(10, scale)
}
/**
 * 根据精度四舍五入
 */
export function roundNum (num, n) {
  var f = parseFloat(num);
  if(isNaN(f)){
      return '0.00';
  }   
  f = Math.round(num*Math.pow(10, n))/Math.pow(10, n); // n 幂   
  var s = f.toString();
  var rs = s.indexOf('.');
  //判定如果是整数，增加小数点再补0
  if(rs < 0){
      rs = s.length;
      s += '.'; 
  }
  while(s.length <= rs + n){
      s += '0';
  }
  return s
}

/**
 * 去除空格
 */
 export function trim(str) {
  return (str || "").replace(/^\s+|\s+$/g, "");
}
/**
 * 去除表情
 */
export function removeEmoji(content) {
  return content.replace(
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
    ""
  );
}

export function format(n, f) {
  n = Number(n);

  if (!Number.isFinite(n)) return null;
  let s = n;
  s = `${s}`.split(".");
  s[0] = s[0]
    .split("")
    .reverse()
    .join("")
    .replace(/(\d{3})/g, function ($1) {
      return $1 + ",";
    })
    .replace(/,$/, "")
    .split("")
    .reverse()
    .join("");

  s[1] = s[1] ? s[1] : 0;

  if (Number.isFinite(f)) {
    s[1] = (s[1]
      ? s[1] + "000000000000000000000"
      : "000000000000000000000"
    ).split("");
    s[1].length = Math.max(0, Math.min(Math.floor(f), 16));
    s[1] = s[1].join("");
    return f < 1 || f > 16 ? s[0] : s[0] + "." + s[1];
  } else {
    return s[1] ? s[0] + "." + s[1] : s[0];
  }
}

export function fixFloat(v) {
  if (!v) return v;
  let string_v = `${v}`;
  let d = string_v.split(".");
  d[1] = d[1].split("");
  for (let i = d[1].length - 1; i >= 0; i--) {
    if (d[1][i] === '0') {
      d[1].splice(i, 1);
    } else {
      break;
    }
  }
  d[1] = d[1].join('');
  return d[1].length ? d[0] + '.' + d[1] : d[0]
}



/**
   * 数字小数位截取
   * 精度以外全部舍弃
   * d -3,-2,-1,0,1,2,3,4
   */
export function digits(v, d = 0) {
  let a = ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0"];
  if (!v && v !== 0) {
    if (!d) return v;
    a.length = d;
    return "0." + a.join("");
  }
  if (d === 0 || d === "0" || !d || !Number(d)) {
    return Math.floor(v);
  }
  // 整数截取
  if (d <= 0) {
    let r = math
      .chain(v)
      .multiply(
        math
          .chain(math.pow(10, math.bignumber(d)))
          .format({ notation: "fixed" })
          .done()
      )
      .format({ notation: "fixed" })
      .done();
    r = Math.floor(r);
    r = math
      .chain(r)
      .divide(
        math
          .chain(math.pow(10, math.bignumber(d)))
          .format({ notation: "fixed" })
          .done()
      )
      .format({ notation: "fixed" })
      .done();
    return r;
  }
  let s = v;
  let c = `${s}`.split(".");
  if (!c[1]) {
    c[1] = "";
  }
  if (c[1].length == d) {
    return s;
  }
  if (c[1].length < d) {
    a.length = d - c[1].length;
    return c[1] ? s + a.join("") : a.length ? s + "." + a.join("") : s;
  }
  if (c[1].length > d) {
    c[1] = c[1].split("");
    c[1].length = d;
    return c[0] + "." + c[1].join("");
  }
  return v;
}

/**
 * 精度截取，精度以外的值按照，0舍去，> 0 向上进一位
 * 12.10 -> 12.1
 * 12.11 -> 12.2
 * @param {Number} value
 * @param {Number} 位数
 */
export function digits2(v, d = 0) {
  if (!v && v !== 0) {
    return digits(v, d);
  }
  if (!d || d === "0" || !d || !Number(d)) {
    return Math.ceil(Number(v));
  }
  let n = Number(d);
  let s = math
    .chain(math.bignumber(Number(v)))
    .multiply(
      math
        .chain(math.pow(10, math.bignumber(d)))
        .format({ notation: "fixed" })
        .done()
    )
    .format({ notation: "fixed" })
    .done();
  s = Math.ceil(Number(s));
  s = math
    .chain(math.bignumber(Number(s)))
    .divide(
      math
        .chain(math.pow(10, math.bignumber(d)))
        .format({ notation: "fixed" })
        .done()
    )
    .format({ notation: "fixed" })
    .done();
  if (d <= 0) {
    return s;
  }
  return digits(s, n);
}

/**
 * 保留小数
 */
 export function fixNum (num,scale) {
  return parseInt(num*scale)/scale
}
/**
 * 保留小数
 */
 export function fixedNum (num,scale) {
  var result = parseFloat(num);
  result = Math.floor(num * 100) / 100;
  var s_x = result.toString(); //将数字转换为字符串
  var pos_decimal = s_x.indexOf('.'); //小数点的索引值
  // 当整数时，pos_decimal=-1 自动补0  
  if (pos_decimal < 0) {
    pos_decimal = s_x.length;
    s_x += '.';
  }
  // 当数字的长度< 小数点索引+2时，补0  
  while (s_x.length <= pos_decimal + 2) {
    s_x += '0';
  }
  return s_x;
}
/**
 * 保留小数
 */
export function changeDecimal(number, bitNum) {
	var f_x = parseFloat(number);
	if (isNaN(f_x)) {
		return 0;
	}
	var s_x = number.toString();
	var pos_decimal = s_x.indexOf('.');
	//没有小数点的加上小数点
	if (pos_decimal < 0) {
		pos_decimal = s_x.length;
		if(bitNum!=0){
			s_x += '.';
		}else{
			//没有小数点还要保留0位小数
			return s_x;
		}
	}
	if(s_x.length <= pos_decimal + bitNum ) {
		//eg:122 保留2位小数
		//return 122.00
		while (s_x.length <= pos_decimal + bitNum) {
			s_x += '0';
		}
	}else{
		//eg:1.222222  保留2位小数
		//return 1.22
		s_x=s_x.substring(0, pos_decimal + bitNum+1)
	}
	return s_x;
}
  /**
 * 将科学计数法的数字转为字符串
 * 说明：运算精度丢失方法中处理数字的时候，如果出现科学计数法，就会导致结果出错  
 * 4.496794759834739e-9  ==> 0.000000004496794759834739
 * 4.496794759834739e+9  ==> 4496794759.834739
 * @param  num 
 */
export function toNonExponential(num,scale) {
  if(num == null) {
      return num;
  }
  if(typeof num == "number") {
      let m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
      let num1= num.toFixed(Math.max(0, (m[1] || '').length - m[2]));
      return Math.round(Number(num1) * Math.pow(10, scale)) / Math.pow(10, scale)
  }else {
      return num;
  }
}
export function debounce(fn, time) {//fn是一个函数，time是延迟时间
  let timeID = null //初始化定时器id
  return function () {
    if (timeID !== null) {
      clearTimeout(timeID) //如果id不为空则清空定时器，从新执行定时器
    }
    timeID = setTimeout(() => {//定时器返回一个id
      fn.call(this)//如果时间到了且id不为空则产生回调函数执行原本的fn函数
    }, time||2000);//如果time不传或为空则默认延迟时间为2s
  }
}

/**
 * 科学计数法处理
 */
export function numToStr (val) {
  if (!val) return 0
  Decimal.set({ toExpNeg: -20 })
  return Decimal(val).valueOf()
}

  /**
   * 
   * @returns {string} urlSuffix url后缀
   */
   export function getSuffix() {
    const host = location.host;
    let urlSuffix = 'com';
    // let urlSuffix = 'com.cn';
    if (host.indexOf('q.wikibit.') > -1) {
      urlSuffix = location.host.replace('q.wikibit.', '');
    }
    return urlSuffix;
  }

/**
 * 转成树形数据
 */
export function toTreeData (data) {
  let arr = JSON.parse(JSON.stringify(data))
  const listChildren = (obj,filter) => {
    [arr , obj.children] = arr.reduce((res,val)=>{
      if(filter(val))
      res[1].push(val) 
      else
      res[0].push(val)
      return res
    },[[],[]])
    obj.children.forEach(val=>{
      if (arr.length)
      listChildren( val , obj => obj.parentCode === val.areaCode)
    })
  }
  const tree = {}
  listChildren(tree, val=>arr.findIndex(i => i.areaCode===val.parentCode)===-1)
  return tree.children

}

/**
 * 转成树形数据
 */
export function toTreeData2 (data) {
  let arr = JSON.parse(JSON.stringify(data))
  const listChildren = (obj,filter) => {
    [arr , obj.children] = arr.reduce((res,val)=>{
      if(filter(val))
      res[1].push(val) 
      else
      res[0].push(val)
      return res
    },[[],[]])
    obj.children.forEach(val=>{
      if (arr.length)
      listChildren( val , obj => obj.parentId == val.id)
    })
  }
  const tree = {}
  listChildren(tree, val=>arr.findIndex(i => i.id==val.parentId)===-1)
  return tree.children

}

 /**
 * 
 */
export function getContext2D(element) {
  if(element) {
    return element.getContext('2d');
  }

  return null;
}
 /**
 * 
 */
export function isEmpty(map) {
  if(!map) {
    return true;
  }

  if(map instanceof Array) {
    return !map.length;
  }

  return !Object.keys(map).length;
}
/**
 * 
 */
 export function toPretty(num) {
  num = num || 0;
  if (num < 100) {
    return num.toFixed(2)
  }
  if (num < 1e6) {
    return (num /1e3).toFixed(1) + ' K'
  }
  if (num < 1e7) {
    return (num / 1e6).toFixed(1) + ' M'
  }
  if (num < 1e9) {
    return (num /1e6).toFixed(0) + ' M'
  }
  if (num < 1e10) {
    return (num / 1e9).toFixed(1) + ' B'
  }
  return (num / 1e9).toFixed(0) + ' B'
}
/**
 * 
 */
 export function toThousand(num = 0) {
  if (typeof num === 'undefined') {
    return 0
  }
  return (num || 0).toString().replace(/\d+/, function (n) {
    let len = n.length;
    if (len % 3 === 0) {
      return n.replace(/(\d{3})/g, ',$1').slice(1)
    } else {
      return n.slice(0, len % 3) + n.slice(len % 3).replace(/(\d{3})/g, ',$1')
    }
  })
}

export function filterSearch({ oldList = [], search = "", key = "value" }) {
  const old_list = [...oldList];
  const new_list = old_list.filter((value) => {
    
    value.baseAsset === search;
    return toSearchString(value.baseAsset).indexOf(toSearchString(search)) !== -1;
  });
  if (search) return new_list;
  return old_list;
}

export function toSearchString(value) {
  return String(value).toLowerCase();
}
  /**
   * bex颜色转为rgba
   * @param String #ff00ff #f0f
   * @param Number a 0-1
   * @return String rgba(r,g,b,a)
   */
   export function hex_to_rgba (hex, a = 1) {
    if (!hex || hex.indexOf("#") == -1) {
      return "rgba(0,0,0,0)";
    }
    if (hex.length != 7 && hex.length != 4) {
      console.error(`${hex} is not hex color`);
      return "rgba(0,0,0,0)";
    }
    let s = hex.replace("#", "").match(/^(..?)(..?)(..?)/);
    return `rgba(${parseInt(
      "0x" + s[1] + (s[1].length == 1 ? s[1] : "")
    )},${parseInt("0x" + s[2] + (s[2].length == 1 ? s[2] : ""))},${parseInt(
      "0x" + s[3] + (s[3].length == 1 ? s[3] : "")
    )},${Number(a)})`;
  }
