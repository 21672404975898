// import * as types from './mutation-types'
import { SET_EXCHANGEINFO,SET_TRADEACTIVE,SET_ZORRO,SET_BASEASSET,SET_SCORE,SET_TRADE,SET_TICKERDAY,SET_WSTICKER,SET_RESOLUTION,SET_ASKS,SET_BIDS,SET_ASKSDEP,SET_BIDSDEP,SET_FIX,SET_TRADELIST,SET_DEPTHDATA,SET_ISLINE,SET_LOCAL,SET_THEME,SET_UPDOWN,SET_WSISOP,SET_TOKENCODE,SET_IP,SET_COUNTRY,SET_QP,SET_QUOTEASSET,SET_BASEASSETLIST,SET_ISSHOW} from './mutation-type'
import {deweight,fixFloat} from '@/utils/baseFn/filter'
import { klineConfig } from "@/utils/config";
const mutations = {
  [SET_EXCHANGEINFO](state, data) {
    const { symbols } = data.excnInfo
    let quoteAssetList = deweight(symbols,'quoteAsset')
    let codeObj = {};
    // let symbolList = [];
    let idx = 0;
    for (let item of state.zorroJson) {
      codeObj[item.BaseTokenName] = item;
    }
      symbols.map((item,index)=>{
        item['code'] = codeObj[item.baseAsset] ? codeObj[item.baseAsset].TokenCode : 88727314080 + idx;
        let priceFilter = item.filters.find(f => f.filterType === "PRICE_FILTER");
        let price = fixFloat(priceFilter.tickSize);
        let sizeFilter = item.filters.find(f => f.filterType === "LOT_SIZE");
        let size = fixFloat(sizeFilter.stepSize);
        item['tickSize'] = klineConfig.depth[price];
        item['stepSize'] = klineConfig.depth[size];
        item['index'] = idx++;
      })
      state.quoteAssetList=quoteAssetList
      state.symbolList = symbols
  },
  [SET_BASEASSETLIST](state, data) {
    const {baseAssetList } = data
    state.baseAssetList = baseAssetList
  },
  [SET_TOKENCODE](state, data) {
    const {TokenCode } = data
    state.TokenCode = TokenCode
  },
  [SET_TRADEACTIVE](state, data) {
    const {tradeActive } = data
    state.tradeActive = tradeActive
  },
  [SET_QUOTEASSET](state, data) {
    const {quoteAsset } = data
    state.quoteAsset = quoteAsset
  },
  [SET_BASEASSET](state, data) {
    const {baseAsset } = data
    state.baseAsset = baseAsset
  },
  [SET_ZORRO](state,data){
    const{zorroJson} = data
    state.zorroJson=zorroJson
  },
  [SET_SCORE](state,data){
    const{score} = data
    state.score=score
  },
  [SET_TRADE](state,data){
    const{trade} = data
    let trades=trade+'/'+state.tradeActive
    let symbol=trade+state.tradeActive
    state.trade=trades
    state.symbol=symbol.toLowerCase()
  },
  [SET_TICKERDAY](state,data){
    const{tickerDay} = data
    let arr=tickerDay.filter(item=>{
      return item.askPrice!=0
    })||[]
    let list = state.symbolList
    let arr1=[]
      if(list&&list.length>0){
        list.forEach(item=>{
          if(arr&&arr.length>0){
            arr.forEach(val=>item.symbol===val.symbol&&arr1.push({...item,...val}));
          }
        });
      }
    state.tickerDayList=arr1
  },
  [SET_WSTICKER](state,data){
    const{wsTickerList} = data
   
    state.wsTickerList=wsTickerList
  },
  [SET_RESOLUTION](state,data){
    const{resolution} = data
   
    state.resolution=resolution
  },
  [SET_DEPTHDATA](state,data){
    const{depthData} = data
    
    state.depthData=depthData
  },
  [SET_ASKS](state,data){
    const{asks} = data
    state.asks=asks
  },
  [SET_BIDS](state,data){
    const{bids} = data
    state.bids=bids
  },
  [SET_ASKSDEP](state,data){
    const{asksDep} = data
    state.asksDep=asksDep
  },
  [SET_BIDSDEP](state,data){
    const{bidsDep} = data
    state.bidsDep=bidsDep
  },
  [SET_FIX](state,data){
    const{fix} = data
    state.fix=fix
  },
  [SET_ISSHOW](state,data){
    const{isShow} = data
    state.isShow=isShow
  },
  [SET_ISLINE](state,data){
    const{isLine} = data
    state.isLine=isLine
  },
  [SET_LOCAL](state,data){
    const{local} = data
    state.local=local
  },
  [SET_UPDOWN](state,data){
    const{upDown} = data
    state.upDown=upDown
  },
  [SET_THEME](state,data){
    const{theme} = data
    state.theme=theme
  },
  [SET_IP](state,data){
    const{ip} = data
    state.ip=ip
  },
  [SET_COUNTRY](state,data){
    const{country} = data
    state.country=country
  },
  [SET_WSISOP](state,data){
    const{wsIsOp} = data
    state.wsIsOp=wsIsOp
  },
  [SET_QP](state,data){
    const{tickSize,stepSize} = data
    state.stepSize=stepSize
    state.tickSize=tickSize
  },
  [SET_TRADELIST](state,data){
    // console.log(data);
    const{tradeList,isClear} = data
    if(isClear){
      state.tradeLis=[]
    }else{
      if(tradeList.length){
        state.tradeList=[]
        state.tradeList=tradeList
      }else{
        state.tradeList.pop()
        state.tradeList.unshift(tradeList)
      }
    }
  },
}

export default mutations